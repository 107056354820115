@import "style/variables.scss";

.articles-subscribed-title {
  font-family: $font-family;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 49px;
  text-align: center;
  color: $black;
}

.no-article-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    text-align: center;
    margin: 25px 0;
  }

  .no-article-image {
    width: 70%;
    height: auto;
  }
}