.custom-toggle {
  color: orange;

  &:hover {
    color: #777
  }

  &:focus {
    outline: none !important;
  }
}

.btn:focus {
  outline: none !important;
}