@import "style/variables.scss";

.EditAvatar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h4 {
    font-family: $font-family;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    margin-bottom: 40px;
  }

  .btn-input-avatar, .btn-submit-avatar {
    min-height: 57px;
    border: none;
    background: linear-gradient(90deg, #FFD251 0%, #FF8A00 50%);
    border-radius: 10px;
    font-weight: 800;
    font-size: 16px;
    line-height: 33px;
    text-align: center;
    color: white;
  }
}