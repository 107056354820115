.MentoringSessionCard {
  width: 18rem;
  background: #FFF2CE;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 26px;
  border: none;

  &:hover {
    cursor: pointer;
    background: white;
  }

  @media screen and (max-width: 576px) {
    width: 85%;
    margin-bottom: 25px;

    .sessionLegendary {
      margin-bottom: 0px;
    }

    .sessionInfos {
      padding-left: 5px;
    }
  }
}