@import "style/variables.scss";

.ProfileMenu {
  text-align: center;
  height: 100%;

  .content-div {
    min-height: calc(100vh - 114px);
    padding-bottom: 0 !important;
  }
}

.user-menu-link {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #434343;
  padding: 10px;
  margin: 25px 0;
}

.user-menu-link:hover {
  color: #292b2c;
  background-color: $light-orange;
  border-radius: 10px;
}

.is-active {
  background: linear-gradient(89.94deg, #FFD251 2.2%, #FF8A00 71.84%);
  border-radius: 10px;
  color: white;
  font-weight: 500;
  pointer-events: none !important;
}

.user-menu-icon {
  margin-left: auto;
}

.logout-icon {
  margin-right: 5px;
  color: #FF8A00;
  font-size: 26px;
}

.logout-text {
  text-transform: uppercase;
  color: $orange-background;
}
