@import "style/variables.scss";


.table-responsive {
  border: none;
  box-shadow: 0px 4px 10px -4px rgba(0, 0, 0, 0.25);
  border-radius: 25px;

  th {
    border: 0px solid #fff;
  }

  tr {
    border: 0px solid #fff;
  }

  .table-striped>tbody>tr:nth-child(odd)>td, 
  .table-striped>tbody>tr:nth-child(odd)>th {
    background-color: #FFF2CE;
  }
}