@import "style/variables.scss";

.Courses {
  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: $primary-old;
    padding: 20px 0 20px;
    color: white;
  }

  .sidemenu {
    background-color: $light-orange;
  }
}


