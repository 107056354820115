@import "style/variables.scss";

.Navigation {
  background-color: $secondary;
  min-height: 114px;
  font-family: $font-family;
  padding: 0 100px 0;
  border-bottom: 1px solid #E5E5E5;
  z-index: 999;

  .btn-login {
    background-color: $orange-background;
    color: white;
    font-weight: bold;
  }

  .nav-link {
    font-size: 20px;
    font-weight: bold;
    margin: 0 10px 0;
    background-image: $primary;
    background-color: $black;
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    background-size: 0, 100%;
    &:hover {
      color: $light-orange !important;
    }
  }

  .btn-admin {
    border: 1px solid $black;
  }

  .btn-company {
    border: 1px solid $black;
  }

  .btn-notion {
    background-color: $orange-background;
    margin-left: 10px;
    a {
      color: white;
      font-weight: bold;
      font-size: 20px;
    }
  }

  .nav-icons {
    color: $primary;
    size: 40;
  }

  .link-position {
    display: flex;
    justify-content: flex-end;
  }

  .user-avatar-navbar {
    width: 25px;
    height: 25px;
  }

  .dashboard-btn {
    padding: 4.5px 9px;
    box-shadow: 0px 4px 4px rgba(166, 163, 160, 0.25);
    border-radius: 10px;
    height: 45px;
  }

  .language-btn {
    width: 65px;
    padding: 0;
    
    .language-icon {
      border-radius: 50%;
      width: 25px;
      height: 25px;
    }
  }

  .arrow-down {
    margin-bottom: 2px;
    color: #A6A3A0;

    &:hover {
      cursor: pointer;
      color: #A6A3A0;
    }
  }

  .dropdown-toggle::after {
    display: none;
  }

  .dropdown-text {
    color: black;
    padding: 25px 15px;
    margin: 10 0;
  }

  .dropdown-menu .dropdown-item:hover {
    background: $orange-background;
    border-radius: 10px;

    a {
      color: white;
    }
  }

  span {
    z-index: +1;
  }

  .mr-5 {
    margin-right: 80px !important;
  }

  img {
    width: 250px;
    height: 80px;
  }

  .dropdown-menu {
    min-width: 25px;
    border-radius: 25px;
  }

  .language-nav {
    max-width: 100px;
  }
}

@media screen and (max-width: 576px) {

  .Navigation {
    width: 85%;
    padding: 0;
    margin: auto;
  }

  .Navigation__logo {
    img {
      width: 150px;
    }
  }

  .dropdown-menu {
    min-width: 25px;
  }

  .language-btn {
    margin-bottom: 65px;
  }

  .language-dropdown {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img {
      max-width: 25px;
      max-height: 25px; 
    }
  }
}
