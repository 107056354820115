@import "style/variables.scss";

.ArticleCard {
  font-family: $font-family;
  background: #FFFFFF;
  border-radius: 34px;

  .article-title {
    color: $orange-background;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 33px;
    margin-bottom: 5px;
  }


  .article-date {
    font-style: normal;
    font-weight: 200;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 30px;
  }


  .article-description {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
  }


  .btn-reader {
    border-radius: 62px;
    margin: 15px 0;
    background: $orange-background;
    border: none;
    width: 50%;
  }
}