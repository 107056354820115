@font-face {
  font-family: "Nunito Sans";
  src: url("../assets/fonts/NunitoSans-Regular.ttf");
}

$footer-size: 15vh;
$font-family: "Nunito Sans", sans-serif;

$primary: -webkit-linear-gradient(to left, #ffc000, #ff8a00)
  /* Chrome 10-25, Safari 5.1-6 */ linear-gradient(to left, #ffc000, #ff8a00)
  #ff8a00;
$primary-alt: linear-gradient(
  to left,
  #ffc000,
  #ff8a00
); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
$primary-old: #ff8a00; /* fallback for old browsers */

$secondary: #ffffff;
$orange-background: #ff8a00;
$light-orange: #fff7df;
$footer-color: #5c5c5c;

$black: #000000;

$card-border-radius: 25px;

// Typo : Nunito
//    -  Hero : 44px
//    -  Hero Body : 18px
//    -  Heading: 40px
//    -  Sub Heading : 18px
//    -  Heading body : 14px
