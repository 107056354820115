@import "style/variables.scss";

.accept-terms-btn {
  font-family: $font-family;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  background: linear-gradient(90deg, #FFD251 0%, #FF8A00 50%);
  box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  color: white;
  padding: 15px 30px;
  border: none;
}