@import "style/variables.scss";

.Lesson {

  .nav {
    margin: 20px 0 30px;
    a {
      color: black;
    }
    .nav-link.active {
      color: #fff !important;
      background-color: #FF8A00 !important;
    }
  }
  img {
    width: 100%;
  }

  video {
    outline: none;
  }

  .col-md-1 {
    flex-grow: 1;
    transition: all 400ms ease;
  }

  .col-md-2 {
    flex-grow: 1;
    transition: all 400ms ease;
  }

  .col-md-3 {
    flex-grow: 1;
    transition: all 400ms ease;
  }

  .col-md-4 {
    flex-grow: 1;
    transition: all 400ms ease;
  }

  .col-md-5 {
    flex-grow: 1;
    transition: all 400ms ease;
  }

  .col-md-6 {
    flex-grow: 1;
    transition: all 400ms ease;
  }

  .not-displayed{
    visibility: hidden;
    transition: opacity 2s ease-in-out;
    -moz-transition: opacity 2s ease-in-out;
    -webkit-transition: opacity 2s ease-in-out;
    opacity: 0;
    height: 0px;
    border: 0px;
  }
  .displayed{
    visibility: visible;
    opacity: 1;
    transition: opacity 2s ease-in-out;
    -moz-transition: opacity 2s ease-in-out;
    -webkit-transition: opacity 2s ease-in-out;
  }

  .progressWrap {
    background-color: #ffffff;
  }

  .video-state-section {
    .video-state-button {
      position: relative;
      left: -0.5 rem;
      width: 20px;
    }

    .video-state-button:hover {
      cursor: pointer;
    }
  }

  .video-state-button {
    padding: 0.5rem 3rem;
    border: none;
    background: #ffffff;
    border: 1px solid $black;
    border-radius: 10px;
    color: $black;
  }

  .lesson-button {
    padding: 0.5rem 3rem;
    border: none;
    background: $orange-background;
    box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    color: white
  }

  @media screen and (max-width: 768px) {
    .right-block  {
      position: relative;
    }

    .progressWrap {
      margin-right: 0px !important;
      padding-right: 0px !important;
    }

    .display-video-or-not  {
      display: none;
    }
  }

  @media screen and (min-width: 768px) {
    .right-block  {
      position: fixed;
    }
  }
}


.quizz-modal {
  min-width: 80vw;
}