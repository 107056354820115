@import "style/variables.scss";


.EditProfile {
  font-family: $font-family;

  h3 {
    font-family: $font-family;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 41px;
    text-align: center;
  }

  .submit-profile {
    min-height: 57px;
    border: none;
    background: $orange-background;
    border-radius: 10px;
    font-weight: 800;
    font-size: 16px;
    line-height: 33px;
    text-align: center;
    color: white;
  }
}