@import "style/variables.scss";

.Sidebar {
  min-height: 100vh;
  height: 100%;
  background-color: $light-orange;

  a,
  button {
    text-decoration: none;
    color: #777;
    font-weight: bold;
    margin: 15px 10px 15px;
    font-size: larger;
    &:hover {
      color: $orange-background;
      text-decoration: none;
    }
  }

  button {
    margin: 10px;
  }

  .active {
    font-size: x-large;
  }

  .accordion a {
    margin-left: 10px;
  }
}


@media screen and (max-width: 768px) {
  .Sidebar {
    height: fit-content;
    padding-bottom: 10px;
    margin-bottom: 30px;
  }
}