@import "style/variables.scss";


.NewMentoringSessionCard {
  font-family: $font-family;
  padding: 20px 0px;
  margin: 20px 0px;
  background: #FFFFFF;
  box-shadow: 0px 4px 10px -4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;

  .new-session-title  {
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 41px;
    text-align: center;
  }

  .mentoring-session-btns {
    background: linear-gradient(90deg, #FFD251 0%, #FF8A00 50%);
    box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    border: none;
  }

  .NewMentoringSessionAccordion {
    border-radius: 25px;
  }
} 