@import "style/variables";


.RecoverPassword {
  font-family: $font-family;
  height: calc(100% - 114px);
  overflow: hidden;

  &_col {
    background-color: white !important;
    padding-left: 0px;
    padding-right: 0px;
  }

  .forgot-password-title {
    font-style: normal;
    font-weight: 800;
    font-size: 48px;
    line-height: 65px;
    text-align: center;
    margin-bottom: 0;
    margin-top: 0;
  }

  .left-side-picture {
    z-index: 0;
  }

  .forgot-text-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    text-align: center;
    position: absolute;
    top: auto;

    .form-div {
      width: 45%;
      .form-control {
        background: #FFFFFF;
        box-shadow: 0px 0px 13px -4px rgba(0, 0, 0, 0.5);
        border-radius: 15px;
        margin: 20px 0;
      }
  
      .submit-password-btn {
        background: linear-gradient(89.94deg, #FFD251 2.2%, #FF8A00 71.84%);
        border-radius: 10px;
        color: white;
        border: none;
        padding: 15px 35px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
        margin: 20px 0;
        font-style: normal;
        font-weight: 900;
      }
  
      .already-account {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        text-align: center;
      }
    }
  }
}

