@import "style/variables.scss";

.ButtonPrimary {
  background-image: linear-gradient(90deg, #FFD251 0%, #FF8A00 50%);
  border-radius: 10px;
  border: none;
  color: white;
  font-family: $font-family;
  font-weight: bold !important;
  &:hover {
    cursor: pointer;
    color: white;
  }

  .largeResponsive {
    width: 75%;
    height: 100%;
  }
}

button:disabled {
  background-image: none;
  background-color: grey !important;
}


