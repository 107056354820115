@import "style/variables.scss";

.courses-card {
  font-family: $font-family;
  background: #FFFFFF;
  box-shadow: 0px 4px 10px -4px rgba(0, 0, 0, 0.25);
  border-radius: $card-border-radius;
  min-height: 100%;
}

.courses-card-title {
  font-family: $font-family;
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 34px;
  text-align: center;
}

.path-avatar {
  border: 0.5px solid #F2F2F1;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.5));
  border-radius: 50%;
  width: 70px;
  height: 70px;

  &:hover {
    cursor: pointer;
  }
}

.btn-courses {
  font-family: $font-family;
  height: fit-content;
  width: 50%;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  background: $orange-background;
  border-radius: 10px;
  border: none;
}

.btn-courses:hover {
  background: $orange-background;
}

@media screen and (min-width: 1400px) {
  .btn-courses {
    height: 40px;
  }
}