@import "style/variables.scss";


.course-toggler {
    &:focus {
    outline: none;
    box-shadow: none;
  }
}


.lesson-list-element:hover {
  background: $orange-background;
  cursor: pointer;
  color: #fff;
  border: none;
}

.id-number {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-right: 10px;
  border: 1px solid $orange-background;
  text-align: center;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  background-color: #ffffff;
  color: $orange-background
}

.left-chapter-card {
  background: #FFFFFF;
  box-shadow: 0px 4px 10px -4px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  font-family: $font-family;
  display: flex;
  flex-direction: column;
  align-items: center;

  .left-chapter-card-image {
    width: 200px;
    height: 200px;
    margin: 20px;
  }

  .course-title {
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    line-height: 35px;
    text-align: center;
    color: $black;
  }

  .course-description {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    padding: 10px;
    text-align: center;
  }
}

.right-chapter-column {
  font-family: $font-family;

  .right-chapter-card {
    margin: 15px 0;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px -4px rgba(0, 0, 0, 0.25);
    border-radius: 25px;

    .card-header {
      background-color: #fff;
    }
  }

  .chapter-title {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    color: $orange-background
  }

  .chapter-description {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    text-align: left;

  }
}

.go-courses-button-section {
  text-align: center;
  margin: 30px 0;

  .go-courses-button {
    background: $orange-background;
    box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px 40px;
    color: white;
    border: none;

    .icon {
      margin-right: 10px;
    }
  }
}