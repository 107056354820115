@import "style/variables.scss";

.ArticlesPage {
  font-family: $font-family;

  .col {
    h5 {
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 39px;
      text-align: center;
    }

    .no-articles-image {
      width: 70%;
      height: auto;
      justify-content: center;
      object-fit: cover;
    }
  }

  .page-articles-title {
    margin-top: 15px;
    text-align: center;
    font-family: $font-family;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 49px;
    text-align: center;
  }

  .sidemenu {
    background-color: $light-orange;
  }
}
