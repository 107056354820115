@import "style/variables.scss";

.CourseCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: $font-family;
  font-size: 18px;

  &__header {
    align-self: flex-end;
    background-image: $primary-alt;
    background-image: $primary;
    border-radius: 0px 10px 0px 10px;
    width: 30%;
    text-align: center;
    color: $secondary;
  }

  &__title {
    width: 100%;
    padding: 10px;
    color: #777;
    font-weight: bold;
  }

  &__cta {
    display: flex;
    top: 0;
  }
}
