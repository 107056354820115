@import "style/variables.scss";

.mentoring-index-card {
  font-family: $font-family;
  background: #FFFFFF;
  box-shadow: 0px 4px 10px -4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  margin: 35px 0;

  .card-body {
    padding: 20px 50px;
  }

  .avatar-session {
    width: 105px;
    height: 105px;
  }

  .session-card-subtitle {
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 7px;
  }

}

.not-default-link,
.not-default-link:hover,
.not-default-link:focus,
.not-default-link:active {
  text-decoration: none;
  color: inherit;
}