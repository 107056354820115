@import "style/variables.scss";

.modal-question-validation-button {
  background-color: $orange-background;
  color: "white";
  border: none;
  padding: 15px 25px;
  box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.modal-question-validation-button:hover {
  background-color: $orange-background;
}

.answer-status {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  color: $orange-background;
  font-weight: bold;
}

.answer-explanation {
  display: flex;
  justify-content: center;
  align-items: center;
}


.next-lesson-button {
  display: flex;
  justify-self: flex-end;
  background-color: $orange-background;
  color: "white";
  border: none;
  padding: 15px 25px;
  border-radius: 10px;
  color: white;
}

.next-lesson-button:hover {
  background-color: $orange-background;
  color: white
}

.go-back-profile {
  align-self: center;
  background-color: $orange-background;
  border: none;
  padding: 15px 25px;
  border-radius: 10px;
  color: white;
  margin-top: 8px;
  text-align: center;
}

.go-back-profile:hover {
  background-color: $orange-background;
  color: white
}