@import "style/variables.scss";

.statistics-cards {
  box-shadow: 0px 4px 10px -4px rgba(0, 0, 0, 0.25);
  border-radius: $card-border-radius;

  .bg-ORANGE_BG {
    background-color: $orange-background !important;
  }

  .company-admin-progressbar {
    border-radius: 40px;

    .progress-bar {
      background: linear-gradient(90deg, #FF8A00 0%, #FFD251 100%);
    }
  }


  .taked-sessions-number {
    font-size: 72px;
    font-weight: 600;
    color: $orange-background;
    text-align: right;
  }

  .rounded-orange-box-course-number {
    background-color: $orange-background;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    color: white;
    margin-right: 15px;
    line-height: 50px;
  } 
}

.last-connected-persons-names {
  text-align: left;
  margin-bottom: 0;
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    left: 0px;
    bottom: -2px;
    width: 0%;
    height: 1px;
    background-color: black;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
  }
  &:hover:before {
    visibility: visible;
    width: 100%;
    transform: translateX(0%);
  }
}

.btn-update-limit {
  background-color: transparent;
  border: none;
  color: $orange-background;
  border: 1px solid $orange-background;
  font-size: 14px;
  font-weight: 600;
  margin-top: 10px;
  &:hover {
    cursor: pointer;
    color: black;
    border: 1px solid black;
  }
}

@media screen and (max-width: 768px) {

  .right-stats-card {
    margin-top: 25px !important;
    .taked-sessions-number {
      text-align: center !important;
    }
  }
}