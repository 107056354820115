@import "style/variables.scss";

.LittleCourseCard {
  width: 18rem;
  margin: 20px 25px;
  border-radius: 34px;
  background: #FFF7DF;
  font-family: $font-family;

  .picture-section {
    .course-picture {
      width: 100px;
      height: 100px;
    }
  }

  .course-title-section {
    margin: 15px 0px;
    p {
      font-style: normal;
      font-weight: 800;
      font-size: 24px;
      line-height: 33px;
      text-align: center;
    }
  }

  .state-section {
    text-align: center;
    position: relative;
    bottom: 0;

    p {
      color: #73716F;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
    }
  }


  .card-footer {
    background-color: transparent;
    border: none;
    margin-bottom: 15px;
    .course-button-section {
      text-align: center;
      .course-button {
        border: none;
        width: 200px;
        height: 54px;
        background: #FFFFFF;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
        border-radius: 62px;
      }
    } 
  }
}