@import "style/variables.scss";

.mentoring-sessions {
  font-family: $font-family;


  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 41px;
    text-align: center;
  }

  .no-sessions {
    text-align: center;
    margin: 25px 0;
  }

  .no-sessions-image {
    width: 100%;
    height: auto;
  }
}