@import "style/variables";

.AdminLogin {
  font-family: $font-family;

  &_col {
    z-index: 10;
    background-color: white !important;
    padding: 40px 0 40px;
  }
  
  .happy {
    font-style: normal;
    font-weight: 800;
    font-size: 48px;
    line-height: 65px;
    text-align: center;
  }

  .left-side-picture {
    z-index: 0;
  }

  .login-text-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    text-align: center;
    position: absolute;
    top: auto;
  }

  .company-btn {
    color: #fff;
    font-weight: bolder;
    background: linear-gradient(89.94deg, #FFD251 2.2%, #FF8A00 71.84%);
    border-radius: 10px;
    border: none;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  }

  .linkedin-btn {
    border-radius: 10px;
    border: none;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);

    .linkedin-link {
      color: #fff;
      cursor: pointer;
    }
  }
}