@import "style/variables.scss";

.signature-card {
  font-family: $font-family;
  font-style: normal;
  margin: 0 auto;
  width: 85%;
  background: #FFFFFF;
  box-shadow: 0px 4px 10px -4px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  border: none;
  padding: 20px 0;

  img {
    height: 200px;
    width: 85%;
    margin-bottom: 15px;
  }

  button {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.10);
    border-radius: 10px;
    border: none;
    min-height: 35px;
    padding: 15px 25px;
  }

  .save-signature-button {
    background: $orange-background;
  }

  .reset-signature-button {
    background: $orange-background;
  }

  .update-signature-button {
    background: $orange-background;
    margin-bottom: 10px;
  }
}