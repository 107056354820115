@import "style/variables.scss";

.Searchbar {
  &__input {
    border-radius: 30px;
    padding: 20px;
  }

  &__input:focus {
    outline: none !important;
    border: 1px solid $light-orange;
    box-shadow: 0 0 10px $orange-background;
  }
}
