@import "style/variables.scss";

.ButtonSecondary{
  background-color: $secondary; 
  border: none;
  border-radius: 100px;
  color: $primary;
  color: #777;
  font-family: $font-family;
}

.large{
  width: 423px;
  height: 60px;
}
.medium{
  width: 160px;
  height: 56px;
}
