@import "style/variables.scss";

code {
  font-size: inherit;
}

p > code,
li > code,
dd > code,
td > code {
  background: #ffeff0;
  word-wrap: break-word;
  box-decoration-break: clone;
  padding: .1rem .3rem .2rem;
  border-radius: .2rem;
}

pre {
  background-color: $light-orange;
  border-radius: 3px;
  padding: 3px 10px;
}

pre code {
  display: block;
  white-space: pre;
  -webkit-overflow-scrolling: touch;
  max-width: 100%;
  min-width: 100px;
  padding: 0;
}

table,
tr,
td,
th {
  border: 1px solid #555;
  padding: 6px;
}

blockquote {
  padding: 0 1em;
  color: #6a737d;
  border-left: 0.25em solid #dfe2e5;
}