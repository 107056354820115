@import "style/variables.scss";

.not-permitted-section {
  font-family: $font-family;
  min-height: 100%;
  width: 100%;

  .left-section {
    text-align: center;

    .oups {
      font-style: normal;
      font-weight: 800;
      font-size: 128px;
      line-height: 175px;
      text-align: center;
    }

    .not-accessible {
      font-style: normal;
      font-weight: 600;
      font-size: 36px;
      line-height: 49px;
      text-align: center;
    }

    .safe-area-btn {
      background: linear-gradient(90deg, #FFD251 0%, #FF8A00 50%);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      border: none;
      padding: 15px 35px;
      color: white;
      font-weight: 800;
      font-size: 18px;
      line-height: 25px;
      text-align: center;
      margin: 15px 0;
    }
  }

  .right-section {
    .not-permitted-image {
      width: 100%;
      height: auto;
    }
  }
}