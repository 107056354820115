@import "style/variables.scss";

.user-stats-card {
  box-shadow: 0px 4px 10px -4px rgba(0, 0, 0, 0.25);
  border-radius: $card-border-radius;
}

.company-user-progressbar {
  border-radius: 40px;
}

.company-accordion {

  .card-header-company {
    padding: 0 !important;
  }

  .company-toggler {
    padding: 0 !important;
    width: 100%;
  }

  .company-student-progressbar {

    .progress-bar {
      background: linear-gradient(90deg, #FF8A00 0%, #FFD251 100%);
    }
  }

  .chapter-title {
    margin: 40px 15px;
    text-decoration: underline;
    font-size: 1.6rem;
  }

  .lesson-list-element {
    &:hover {
      .bolder-result {
        color: #000;
      }
    }
  }

  .bolder-result {
    font-weight: bolder;
    font-size: 16px;
    color: $orange-background;
  }
}
