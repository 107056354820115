@import "style/variables.scss";

.pagination-orange {
  .page-item.active .page-link {
    color: #fff;
    background-color: $orange-background;
    border-color: $orange-background;
  }
  
  .page-link {
    color: $orange-background;
    background-color: #fff;
    border-color: $orange-background;
    &:hover {
      color: $orange-background;
      background-color: $light-orange;
      border-color: $orange-background;
      cursor: pointer;
    }
  }
}