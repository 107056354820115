@import "style/variables.scss";


.glossary-container {
  font-family: $font-family;

  .glossary-title {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 49px;
    text-align: center;
    color: $black;

    .icon {
      font-size: 36px;
    }
  }
  
  .search-input {
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(197, 197, 197, 0.25);
    border-radius: 25px;
    height: 66px;
  }
  .alphabetnav {
    display: flex;
    justify-content: center;
  }
}