@import "style/variables.scss";

.ChapterProgressBar {
  background-color: #ffffff;
  padding: 15px;
  width: 100%;
  min-height: 90vh;
  height: 100%;
  font-family: $font-family;

  .chapter-title {
    color: $orange-background;
  }

  &__unread {
    color: lighten($orange-background, 20%);
  }

  svg {
    margin: 0 10px 0;
  }
  a {
    text-decoration: "none";
    color: black;
    &:hover {
      color: lighten($orange-background, 20%);
    }
  }
}

.lessonItem {
  background-color: #ffffff;
  border: none;
  font-family: $font-family;
}

.title-line {
  height:50px;
  width:3px;
  background: $orange-background;
  background: -webkit-linear-gradient(#FF8A00, #FFD251);
  background:    -moz-linear-gradient(#FF8A00, #FFD251);
  background:         linear-gradient(#FF8A00, #FFD251);
  margin-left:5.3px;
}

.line {
  height:50px;
  width:3px;
  background: $orange-background;
  background: -webkit-linear-gradient(#FF8A00, #FFD251);
  background: -moz-linear-gradient(#FF8A00, #FFD251);
  background: linear-gradient(#FF8A00, #FFD251);
}

.active-item {
  color: $orange-background;
  color: linear-gradient(#FFD251, #FF8A00);
  color: -webkit-linear-gradient(#FFD251, #FF8A00);
  color: -moz-linear-gradient(#FFD251, #FF8A00);
}

.inactive-item {
  color: #FFD251;
}


.btn-sidebar {
  position: absolute;
  background-color: #ffffff;
  border: none;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  box-shadow: 1px 0 3px -2px rgba(0 ,0, 0, .3);
  left: 100%;
  top: 0;
  transform: translateX(-60%);
  cursor: pointer;
  z-index: 1;
  color: $black;
}

@media screen and (max-width: 768px) {
  .ChapterProgressBar {
    min-height: 40px;
  }

  .btn-sidebar {
    position: absolute;
    background-color: #ffffff;
    border: none;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    box-shadow: 1px 0 3px -2px rgba(0 ,0, 0, .3);
    left: 50%;
    top: -2%;
    bottom: 0;
    transform: translateX(-60%);
    cursor: pointer;
    z-index: 1;
  }
}