@import "style/variables.scss";

.bookmark-banner {
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  background-color: $light-orange;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1;
  cursor: pointer;

  .bookmark-banner-text {
    padding-left: 10px;
    margin-bottom: 0;
    color: $black;
    text-shadow: 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.2);
  }

  .progress-bookmark {
    margin-top: 10px;
    border-radius: 0;
    transition: width 0.6s ease;

    .progress-bar {
      background: linear-gradient(to right, #FF8A00, #FFD251 );
    }
  }
}