@import "style/variables.scss";

.confirm-presence-btn {
  font-family: $font-family;
  background: linear-gradient(90deg, #FFD251 0%, #FF8A00 50%);
  box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  color: white;
  border: none;
  padding: 15px 40px;
}