@import "./variables";
@import "bootstrap/dist/css/bootstrap.min.css";

html {
  position: relative;
  font-family: $font-family !important;
  font-size: 18px !important;
  @media screen {
    width: 100%;
  }
  overflow-x: hidden;
}

html,
body,
#root,
.App {
  height: 100%;
}

Footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: $footer-size; /* #root margin-bottom */
  background-color: $footer-color !important;
}

a {
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
}

p {
  font-family: $font-family;
  font-size: 22px;
}

h2 {
  font-weight: bolder;
  margin: 25px 0 25px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family;
}

button:focus,
button:active,
button:hover,
.btn:active,
.btn.active {
  outline: 0px !important;
  -webkit-appearance: none;
  box-shadow: none !important;
}

button:hover {
  cursor: pointer;
}

td > svg:hover {
  cursor: pointer;
}

.badge {
  .close {
    margin-left: 0.25rem;
    color: inherit;
    font-size: 100%;
    text-shadow: 0 1px 0 rgba(#000, 0.5);
  }
}

.visible {
  opacity: 1;
  transition: opacity .5s ease-in;
}

.not-visible {
  opacity: 0;
  transition: opacity .5s ease-in;
}

.styled-label {
  font-family: $font-family;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: $black;
}

.input-styled {
  box-shadow: 0px 4px 10px -4px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  min-height: 70px;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #FFF2CE #FFD251;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #FFF2CE;
}

*::-webkit-scrollbar-thumb {
  background-color: #FFD251;
  border-radius: 20px;
  border: 3px solid #FFF2CE;
}
