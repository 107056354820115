@import "style/variables.scss";

.subscription-card {
  font-family: $font-family;
  background: #FFFFFF;
  box-shadow: 0px 4px 10px -4px rgba(0, 0, 0, 0.25);
  border-radius: $card-border-radius;

  .subscription-title {
    font-family: $font-family;
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 34px;
    color: $black;
    text-align: center;
  }

  .bolded-span {
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
  }

  .advancement-percent {
    font-weight: 800;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
  }

  .progressbar-subscription {
    border-radius: 40px;

    .progress-bar {
      background: linear-gradient(90deg, #FF8A00 0%, #FFD251 100%);
    }
  }
}