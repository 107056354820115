
@media screen and (min-width: 577px) {
  
  .validation-btn {
    margin-right: 10px;
  }
  .cancel-btn {
    margin: 10px;
  }
}

@media screen and (max-width: 576px) {

  .validation-btn {
    display: block;
    margin: 0 auto;
  }
  .cancel-btn {
    display: block;
    margin: 10px auto;
  }
}

.status {
  min-width: 150px;
}

.price-column {
  max-width: 150px;

    input {
      max-width: 120px;
    }
}