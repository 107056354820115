@import "style/variables.scss";

.feed-column {
  padding: 0 55px;
  min-height: 100%;
  background-color: #FDFAFA;
  font-family: $font-family;
  
}

