@import "style/variables.scss";

.LearningPaths {
  &__header {
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: $light-orange;
    padding: 20px 0 20px;
    color: #777;
  }

  .sidemenu {
    background-color: $light-orange;
  }
}

.LearningPathShow {
  h3 {
    margin: 15px 0 15px;
    text-align: center;
    color: #777;
    font-weight: bolder;
  }
}
