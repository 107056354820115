@import "style/variables.scss";

.ProfileDisplay {

  .user-identity {
    font-family: $font-family;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    
    a {
      margin-left: 10px;
      position: relative;
      top: -2px;
    }
  }
}
