@import "style/variables";


.LessonQuizz {
  width: 100%;
}

.modal-next-question-button {
  background-color: $orange-background;
  border: none;
  color: "white";
  border-radius: 10px;
}

.modal-next-question-button:hover {
  background-color: $orange-background;
}


