@import "style/variables.scss";

.MentoringCard {
  font-family: $font-family;
  box-shadow: 0px 4px 10px -4px rgba(0, 0, 0, 0.25);
  border-radius: $card-border-radius;
  
  .mentoring-card-title {
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 34px;
    text-align: center;
  }
  @media screen and (max-width: 576px) {
    button {
      width: 100%;
      height: fit-content;
    }
  }

  @media screen and (min-width: 577px) and (max-width: 1199px) {
    button {
      width: 75%;
      height: fit-content;
    }
  }
}

.not-default-link > a,
.not-default-link > a:hover,
.not-default-link > a:focus,
.not-default-link > a:active {
  text-decoration: none;
  color: inherit;
}

.buttonMentoringSession {
  background: #FFFFFF;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  border: 2px solid $orange-background;
  color: $orange-background;
  font-weight: 800;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  width: 100%;
  &:hover, &:focus {
    cursor: pointer;
    color: $orange-background;
    background: white;
    border: 2px solid $orange-background;
  }

  @media screen and (min-width: 1200px) {
    height: 53px;
  }
}