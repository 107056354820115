@import "style/variables.scss";

input[type="checkbox"] {

  &:hover {
    + label {
      cursor: pointer;
    }
  }
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  border-color: $orange-background;
  background-color: $orange-background;
}

.custom-checkbox .custom-control-input ~ .custom-control-label::before {
  border-radius: 30px;
}

input:focus, textarea:focus {
  outline: none !important;
  border: 1px solid rgba(0, 0, 0, 0.55) !important;
  box-shadow: 0px 4px 10px -4px rgba(0, 0, 0, 0.25) !important;
}