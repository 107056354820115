@import "/src/style/variables";

.modal-80w {
  width: 80%;
  max-width: none !important;
  margin-top: 7%;
}

.nav-item.nav-link {
  color: black;
}

.nav-item.nav-link.active {
  color: $orange-background;
}