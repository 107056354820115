@import "style/variables.scss";

.article_chapter_header {
  font-family: $font-family;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 150px;
  background-color: white;
  color: black;
}

.article_chapter_content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 30px;
}

.next_chapter_btn {

  border: none;
  background: linear-gradient(90deg, #FFD251 0%, #FF8A00 50%);
  box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  &:hover {
    background-color: black;
  }
}

.not-permitted-section {
  height: calc(100vh - 114px);
  display: flex;
  flex-direction: row;
  align-items: center;
}